import { operatingInfo } from './operatingInfo';
import { user } from './user';

export default {
  namespaced: true,
  modules: {
    operatingInfo,
    user,
  },
};
