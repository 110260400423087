export default {
  setUser(state, user) {
    state.user = user;
  },

  setAuthInfo(state, info) {
    state.authInfo = info;
  },

  setToken(state, payload) {
    state.token = payload;
  },

  setAuthenticationState(state, isLoading) {
    state.isAuthenticating = isLoading;
  },
};
