export default {
  addFetches(state) {
    state.activeFetches += 1;
  },

  removeFetches(state) {
    state.activeFetches -= 1;
  },

  setIsLoading(state, { isLoading }) {
    state.isLoading = isLoading;
  },

  setIssues(state, { data }) {
    state.issues = data;
  },

  isLoadingHistory(state, isLoadingHistory) {
    state.isLoadingHistory = isLoadingHistory;
  },

  setHistoryIssues(state, { issues }) {
    state.history.push(...issues);
  },

  setHistoryBatchKey(state, batchKey) {
    state.historyBatchKey = batchKey;
  },

  addIssue(state, payload) {
    state.issues.push(payload);
  },

  updateIssue(state, payload) {
    let itemIndex = state.issues.findIndex((issue) => issue.id === payload.id);

    if (itemIndex > -1) {
      state.issues.splice(itemIndex, 1, payload);
      return;
    }

    itemIndex = state.history.findIndex((issue) => issue.id === payload.id);

    if (itemIndex > -1) {
      state.history.splice(itemIndex, 1, payload);
    }
  },

  deleteIssue(state, { id }) {
    let itemIndex = state.issues.findIndex((issue) => issue.id === id);

    if (itemIndex > -1) {
      state.issues.splice(itemIndex, 1);
      return;
    }

    itemIndex = state.history.findIndex((issue) => issue.id === id);

    if (itemIndex > -1) {
      state.history.splice(itemIndex, 1);
    }
  },

  setBanks(state, { data }) {
    state.banks = data;
  },

  setIsModalOpen(state, payload) {
    state.modal.isOpen = payload;
  },

  setModalIssue(state, payload) {
    state.modal.issue = payload;
  },
};
