import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { v4 as uuidV4 } from 'uuid';

// Bugsnag user ID
const getUserId = () => {
  let uid = localStorage.getItem('bs_err_uid');
  if (!uid) {
    uid = uuidV4();
    localStorage.setItem('bs_err_uid', uid);
  }
  return uid;
};

export const bugsnagConfig = {
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  enabledReleaseStages: ['production', 'test'],
  releaseStage: process.env.VUE_APP_BUGSNAG_ENV,
  plugins: [new BugsnagPluginVue()],
  collectUserIp: false,
  user: { id: getUserId() },
  logger: null,

  onError: (event) => {
    event.clearMetadata('vue', 'props'); // Do not send personal data in props to Bugsnag
  },

  onBreadcrumb: (breadcrumb) => {
    if (breadcrumb.type === 'user') {
      // User interaction
      if (breadcrumb.metadata.targetSelector === 'SPAN.name') {
        // Click on top menu, span with class 'name'
        // eslint-disable-next-line no-param-reassign
        breadcrumb.metadata.targetText = '[removed]'; // Do not send name to Bugsnag
      }
    }
  },
};
