import { saveAs } from 'file-saver';
import moment from 'moment';
import certApi, { SessionError } from '@/_company/helpers/certApi';

export const setCertificates = async ({ commit, dispatch }) => {
  try {
    const certRes = await certApi.list();
    commit('setCertificates', { certificates: certRes });
  } catch (error) {
    await dispatch('handleRequestError', error);
  }
};

export const createCert = async ({ rootState, dispatch }, { type, publicKey }) => {
  try {
    const { bic } = rootState.company.user.user.swishService;
    const certRes = await certApi.create(type, bic, publicKey);
    dispatch('setCertificates'); // Update certificates list
    return certRes;
  } catch (error) {
    await dispatch('handleRequestError', error);
    return null;
  }
};

export const revokeCert = async ({ dispatch }, id) => {
  try {
    await certApi.revoke(id);
    await dispatch('setCertificates'); // Update certificates list
  } catch (error) {
    await dispatch('handleRequestError', error);
  }
};

export const downloadCert = async (context, cert) => {
  const data = cert.certEncoded;
  const fileTimestamp = moment(cert.beginTime).format('YYYYMMDDHHmm');
  const fileName = `swish_certificate_${fileTimestamp}.pem`;
  const blob = new Blob([data], { type: 'text/plain' });

  // Fix for IE
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    saveAs(blob, fileName);
  }
};

export const handleRequestError = async ({ dispatch }, error) => {
  if (error instanceof SessionError) {
    dispatch('company/user/clear', {}, { root: true });
    dispatch('company/user/setIsSessionWarningVisible', true, { root: true });
  } else if (error.message) {
    throw new Error(error.message);
  } else {
    throw new Error(error);
  }

  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.error(error);
};
