import { createI18n } from 'vue-i18n';

import en from './locales/en';
import sv from './locales/sv';

const messages = {
  sv,
  en,
};

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'sv',
  fallbackLocale: 'sv',
  messages,
});
