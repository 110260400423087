import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

// Firebase config
const firebaseConfig = {
  apiKey: process.env.VUE_APP_OPERATING_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_OPERATING_FIREBASE_DOMAIN,
  projectId: process.env.VUE_APP_OPERATING_FIREBASE_PROJECT_ID,
};

export const firebaseApp = initializeApp(firebaseConfig);

if (
  process.env.NODE_ENV === 'development' &&
  process.env.VUE_APP_EMULATE_FIREBASE_AUTH === 'true'
) {
  connectAuthEmulator(getAuth(firebaseApp), process.env.VUE_APP_EMULATE_FIREBASE_AUTH_HOST);
}
