import * as actions from './actions';
import mutations from './mutations';

const state = {
  platforms: [
    { name: 'Mac', value: 'mac', navigatorValue: 'mac' },
    { name: 'Windows', value: 'windows', navigatorValue: 'win' },
    { name: 'Linux', value: 'linux', navigatorValue: 'linux' },
  ],
  selectedPlatform: '',
};

export const settings = {
  namespaced: true,
  state,
  actions,
  mutations,
};
