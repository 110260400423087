/* eslint-disable */

const contentful = require('contentful');

const client = contentful.createClient({
  space: process.env.VUE_APP_CONTENTFUL_PORTAL_SPACE_ID,
  accessToken: process.env.VUE_APP_CONTENTFUL_PORTAL_ACCESS_TOKEN,
  host: process.env.VUE_APP_CONTENTFUL_HOST,
  environment: process.env.VUE_APP_CONTENTFUL_PORTAL_ENV,
});

client.getOperatingInfoTemplates = (query = {}) => {
  const q = {};
  Object.keys(query).forEach((key) => {
    q[`fields.${key}`] = query[key];
  });
  return client.withAllLocales
    .getEntries(Object.assign({ content_type: 'operationalMessageTemplate'}, q))
    .then((res) => res.items[0])
    .then((res) => {
      if (res) {
        const channelMessages = {
          appPrivate: { title: {}, message: {} },
          appBusiness: { title: {}, message: {} },
          webExternal: { title: {}, message: {} },
          webBusiness: { title: {}, message: {} },
          apiExternal: { title: {}, message: {} },
        };

        const {
          appPrivate_title,
          appPrivate_message,
          appBusiness_title,
          appBusiness_message,
          webExternal_title,
          webExternal_message,
          webBusiness_title,
          webBusiness_message,
          apiExternal_title,
          apiExternal_message,
        } = res.fields;

        channelMessages.appPrivate.title.sv = appPrivate_title ? appPrivate_title.sv : '';
        channelMessages.appPrivate.message.sv = appPrivate_message ? appPrivate_message.sv : '';
        channelMessages.appBusiness.title.sv = appBusiness_title ? appBusiness_title.sv : '';
        channelMessages.appBusiness.message.sv = appBusiness_message ? appBusiness_message.sv : '';
        channelMessages.webExternal.title.sv = webExternal_title ? webExternal_title.sv : '';
        channelMessages.webExternal.message.sv = webExternal_message ? webExternal_message.sv : '';
        channelMessages.webBusiness.title.sv = webBusiness_title ? webBusiness_title.sv : '';
        channelMessages.webBusiness.message.sv = webBusiness_message ? webBusiness_message.sv : '';
        channelMessages.apiExternal.title.sv = apiExternal_title ? apiExternal_title.sv : '';
        channelMessages.apiExternal.message.sv = apiExternal_message ? apiExternal_message.sv : '';
        channelMessages.appPrivate.title.en = appPrivate_title ? appPrivate_title.en : '';
        channelMessages.appPrivate.message.en = appPrivate_message ? appPrivate_message.en : '';
        channelMessages.appBusiness.title.en = appBusiness_title ? appBusiness_title.en : '';
        channelMessages.appBusiness.message.en = appBusiness_message ? appBusiness_message.en : '';
        channelMessages.webExternal.title.en = webExternal_title ? webExternal_title.en : '';
        channelMessages.webExternal.message.en = webExternal_message ? webExternal_message.en : '';
        channelMessages.webBusiness.title.en = webBusiness_title ? webBusiness_title.en : '';
        channelMessages.webBusiness.message.en = webBusiness_message ? webBusiness_message.en : '';
        channelMessages.apiExternal.title.en = apiExternal_title ? apiExternal_title.en : '';
        channelMessages.apiExternal.message.en = apiExternal_message ? apiExternal_message.en : '';

        return channelMessages;
      }

      return {};
    });
};

export default client;
