<script setup>
import { SwButton } from '@getswish/design-library-web';

const emit = defineEmits(['onDismiss']);

const getExpireDate = () => {
  const expireDate = new Date();
  return new Date(expireDate.setFullYear(expireDate.getFullYear() + 1)).toUTCString();
};

const onClick = (isConsenting) => {
  const expireDate = getExpireDate();
  document.cookie = `cookie_consent_status=dismiss;expires=${expireDate}`;

  if (isConsenting) {
    document.cookie = `opt-in=true;expires=${expireDate}`;
  }

  emit('onDismiss');
};
</script>

<template>
  <div class="cookie-banner">
    {{ $t('cookieBanner.text') }}

    <div class="buttons">
      <SwButton :label="$t('common.no')" @click="onClick(false)" variant="tertiary" size="fill" />
      <SwButton :label="$t('common.yes')" @click="onClick(true)" size="fill" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cookie-banner {
  @include secondary-text-medium;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  background: $secondary-surface;
  border-top: solid 1px $secondary-border;
  padding: $space-24;

  @include tablet-large {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-24;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: $space-16;
    margin-top: $space-24;

    @include tablet-large {
      justify-content: center;
      margin-top: 0;
    }
  }
}
</style>
