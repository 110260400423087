const contentful = require('contentful');

const client = contentful.createClient({
  space: process.env.VUE_APP_CONTENTFUL_EXTERNAL_SPACE_ID,
  accessToken: process.env.VUE_APP_CONTENTFUL_EXTERNAL_ACCESS_TOKEN,
  host: process.env.VUE_APP_CONTENTFUL_HOST,
  environment: process.env.VUE_APP_CONTENTFUL_EXTERNAL_ENV,
});

client.getBanks = () => client.getEntries({
  content_type: 'bank',
}).then((res) => res.items);

export default client;
