const BASE_URL = process.env.VUE_APP_OPERATING_CERT_API_URL;

export class SessionError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SessionError';
  }
}

const fetchWithErrorHandling = async (uri, options, loginFlow = false) => {
  const response = await fetch(uri, { ...options, credentials: 'include' });

  if (!response.ok) {
    if (response.status === 401 && !loginFlow) throw new SessionError('Session has expired');

    let errorToReturn;
    try {
      const responseData = await response.json();
      errorToReturn = new Error(responseData.message);
    } catch (error) {
      errorToReturn = new Error(error);
    }

    throw errorToReturn;
  }

  return response.json();
};

const certApi = {
  logout: () => {
    const uri = `${BASE_URL}/logout`;

    return fetch(uri, { method: 'POST', credentials: 'include' });
  },

  authenticate: async (method, body) => {
    const { ssn, orgNr, swishNr } = body;

    let uri;

    if (method === 'mobileBankId') {
      uri = `${BASE_URL}/authenticate/mobileBankId/${ssn}/${orgNr}/${swishNr}`;
    }

    if (method === 'bankIdOnCard') {
      uri = `${BASE_URL}/authenticate/bankIdOnCard/${orgNr}/${swishNr}`;
    }

    return fetchWithErrorHandling(uri, { method: 'POST' }, true);
  },

  collect: async (orderRef) => {
    const uri = `${BASE_URL}/authenticate/collect/${orderRef}`;

    return fetchWithErrorHandling(uri, {
      method: 'POST',
    });
  },

  qr: async (orderRef, qrss, secondsElapsed) => {
    const uri = `${BASE_URL}/qr/${secondsElapsed}/${orderRef}`;

    return fetchWithErrorHandling(uri, {
      method: 'GET',
      headers: {
        QRSS: qrss,
      },
    });
  },

  getPoc: async () => {
    const uri = `${BASE_URL}/getPoc`;

    return fetchWithErrorHandling(uri, {
      method: 'GET',
    });
  },

  list: async () => {
    const uri = `${BASE_URL}/list`;

    return fetchWithErrorHandling(uri, {
      method: 'GET',
    });
  },

  create: async (type, bic, publicKey) => {
    const uri = `${BASE_URL}/requestNew/${type}/${bic}`;
    const data = `publicKey=${encodeURIComponent(publicKey)}`;

    return fetchWithErrorHandling(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    });
  },

  revoke: async (id) => {
    const uri = `${BASE_URL}/revoke/${id}`;

    return fetch(uri, {
      method: 'DELETE',
      credentials: 'include',
    });
  },
};

export default certApi;
