export const toggleSidebar = ({ state, commit }) => {
  commit('setSidebarIsOpen', !state.sidebar.isOpen);
};

export const openSidebar = ({ commit }) => {
  commit('setSidebarIsOpen', true);
};

export const closeSidebar = ({ commit }) => {
  commit('setSidebarIsOpen', false);
};
