import { createStore } from 'vuex';

import company from './_company/stores/index';
import internal from './_internal/stores/index';

// Shared stores
import common from './stores/common';
import locale from './stores/locale';

export const store = createStore({
  modules: {
    internal,
    company,
    common,
    locale,
  },
});
