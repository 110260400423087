import contentful from '@/_internal/helpers/contentful';
import operatingInfoApi from '@/_internal/helpers/operatingInfoApi';

export const fetchIssues = async ({ commit }) => {
  commit('addFetches');

  try {
    const issues = await operatingInfoApi.list();

    commit('setIssues', { data: issues });
    commit('removeFetches');
  } catch (err) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(err);
  }
};

export const fetchIssueById = async ({ commit }, id) => {
  commit('addFetches');

  try {
    const issue = await operatingInfoApi.show(id);

    commit('setIssues', { data: [issue] });
    commit('removeFetches');
  } catch (err) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(err);
  }
};

export const fetchHistory = async ({ commit }, batchKey) => {
  commit('isLoadingHistory', true);

  try {
    const { issues, lastKey } = await operatingInfoApi.history(batchKey);

    commit('setHistoryIssues', { issues });
    commit('setHistoryBatchKey', lastKey);
    commit('isLoadingHistory', false);
  } catch (err) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(err);
  }
};

export const addIssue = async ({ commit }, issue) => {
  commit('addFetches');

  try {
    const newIssue = await operatingInfoApi.create(issue);
    commit('addIssue', newIssue);
    commit('removeFetches');
  } catch (err) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(err);
    commit('removeFetches');
  }
};

export const updateIssue = async ({ commit }, issue) => {
  commit('addFetches');

  try {
    await operatingInfoApi.update(issue);
    commit('updateIssue', issue);
    commit('removeFetches');
  } catch (err) {
    commit('removeFetches');
  }
};

export const deleteIssue = async ({ commit }, issue) => {
  commit('addFetches');

  try {
    await operatingInfoApi.delete(issue.id);
    commit('deleteIssue', issue);
    commit('removeFetches');
  } catch (err) {
    commit('removeFetches');
  }
};

export const resolveIssue = async ({ commit }, issue) => {
  commit('addFetches');

  try {
    await operatingInfoApi.resolve(issue.id);
    commit('removeFetches');
  } catch (err) {
    commit('removeFetches');
  }
};

// TODO: Move to global-scope and merge with contentful.js
export const fetchBanks = ({ commit }) => {
  contentful.external.getBanks().then((banks) => {
    commit('setBanks', { data: banks });
  });
};
