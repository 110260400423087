// import Bugsnag from '@bugsnag/js';
import Bugsnag from '@bugsnag/js';
import Maska from 'maska';
import { createApp } from 'vue';
import App from './App.vue';

import { bugsnagConfig } from './bugsnagConfig';
import { i18n } from './i18n';
import { router } from './router';
import { store } from './store';

// import '@getswish/design-library-web/dist/style.css';
// import '@getswish/design-library-web/scss/styles/index.scss';

import '@getswish/design-library-web/dist/style.css';
import '@getswish/design-library-web/scss/styles/index.scss';
import '@/scss/styles/index.scss';

// Bugsnag
Bugsnag.start(bugsnagConfig);
const bugsnagVue = Bugsnag.getPlugin('vue');

const app = createApp(App);

app.use(bugsnagVue);
app.use(Maska);

app.use(router);
app.use(store);
app.use(i18n);

const initMSW = async () => {
  // eslint-disable-next-line
  console.log('Using mocked backend.');
  // eslint-disable-next-line
  const { worker } = await require('./__mocks__/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
};

if (process.env.VUE_APP_MOCK_BACKEND === 'true' && process.env.NODE_ENV === 'development') {
  initMSW().then(() => {
    app.mount('#app');
  });
} else {
  app.mount('#app');
}
