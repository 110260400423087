export default {
  setSessionId(state, { sessionId }) {
    state.sessionId = sessionId;
  },

  setIsAuthenticating(state, payload) {
    state.isAuthenticating = payload;
  },

  setLoginState(state, payload) {
    state.loginState = payload;
  },

  setCollectState(state, payload) {
    state.collectState = payload;
  },

  setQrData(state, payload) {
    state.qrData = payload;
  },

  setReadyToCollect(state, payload) {
    state.readyToCollect = payload;
  },

  setPoc(state, { user }) {
    state.user = user;
  },

  setOrganizationName(state, payload) {
    state.organizationName = payload;
  },

  setIsSessionWarningVisible(state, payload) {
    state.isSessionWarningVisible = payload;
  },
};
