import certApi from '@/_company/helpers/certApi';
import { generateQRCode } from '@/_company/helpers/login';
import orgSearchApi from '@/_company/helpers/orgSearchApi';
import { poll } from '@/utils';

const handleError = (error) => {
  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV === 'development') console.error(error);

  if (error.message) {
    throw new Error(error.message);
  } else {
    throw new Error(error);
  }
};

const openBankIdApp = (token) => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = `bankid:///?autostarttoken=${token}&redirect=null`;
  document.body.appendChild(iframe);
};

export const initUser = async ({ state, dispatch }) => {
  if (state.user) {
    return;
  }

  await dispatch('setPoc');
};

export const login = async ({ commit, dispatch }, { method, data }) => {
  commit('setLoginState', 'auth');
  let authRes;

  try {
    authRes = await certApi.authenticate(method, data);
  } catch (error) {
    commit('setLoginState', 'error');
    dispatch('clear');
    handleError(error);
  }

  if (authRes) {
    await dispatch('collect', { authRes, method });
  }
};

export const collect = async ({ commit, dispatch, state }, { authRes, method }) => {
  commit('setReadyToCollect', true);

  if (method === 'bankIdOnCard') {
    openBankIdApp(authRes.autoStartToken);
  }

  let pollCount = -1;
  const pollOptionsQR = {
    interval: 1000,
    timeLimit: 30000,
    asyncFn: () => {
      pollCount += 1;
      return certApi.qr(authRes.qrStartToken, authRes.qrss, pollCount);
    },
    pollCallback: async (res) => {
      const qrData = await generateQRCode(res.code);
      if (qrData && state.loginState === 'auth') commit('setLoginState', 'scan');
      commit('setQrData', qrData);
    },
    successCriteria: () => {},
    successCallback: () => {},
    timeoutCallback: () => {},
    errorCallback: () => {},
  };

  const qrPoller = poll(pollOptionsQR);

  qrPoller.start();

  try {
    const collectResult = await certApi.collect(authRes.orderRef);
    qrPoller.cancel();

    if (collectResult === 'COMPLETE') {
      commit('setLoginState', 'fetching');
      commit('setCollectState', collectResult);
      commit('setQrData', '');
      dispatch('setPoc');
    }
  } catch (error) {
    qrPoller.cancel();
    dispatch('clear');
    handleError(error);
  }
};

export const setPoc = async ({ commit, dispatch }) => {
  try {
    const userRes = await certApi.getPoc();

    commit('setPoc', { user: userRes });
    commit('setLoginState', 'complete');
  } catch (error) {
    dispatch('clear');
  }
};

export const logout = async ({ dispatch }) => {
  await certApi.logout();
  dispatch('clear');
};

export const clear = async ({ commit }) => {
  commit('setLoginState', 'idle');
  commit('setReadyToCollect', false);
  commit('setQrData', '');
  commit('setCollectState', '');
  commit('setPoc', { user: null });
  commit('company/certificates/setCertificates', { certificates: [] }, { root: true });
};

export const setIsSessionWarningVisible = async ({ commit }, payload) => {
  commit('setIsSessionWarningVisible', payload);
};

export const setOrganizationName = async ({ commit, state }) => {
  const orgNo = state.user.swishService.organizationNumber;

  try {
    const organizationName = await orgSearchApi.search(orgNo);
    commit('setOrganizationName', organizationName);
  } catch (error) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV === 'development') console.error(error);
  }
};
