import { http, HttpResponse, delay } from 'msw';
import { authenticate, poc } from '../__fixtures__/auth';
import { list } from '../__fixtures__/certificates';

const BASE_URL = process.env.VUE_APP_OPERATING_CERT_API_URL;

const statuses = [
  'OUTSTANDING_TRANSACTION',
  'NO_CLIENT',
  'STARTED',
  'USER_SIGN',
  'USER_REQ',
  'COMPLETE',
];

export const handlers = [
  http.post(`${BASE_URL}/authenticate/mobileBankId/*/*/*`, async () => {
    await delay();

    return HttpResponse.json(authenticate, { status: 200 });
  }),

  http.post(`${BASE_URL}/authenticate/collect/*`, async () => {
    await delay(5000);

    return HttpResponse.json(statuses[5]);
  }),

  http.get(`${BASE_URL}/qr/*/*`, async () => {
    await delay();

    const randomString = `${Math.random()}${Math.random()}`;

    return HttpResponse.json({
      code: `bankid.67df3917-fa0d-44e5-b327-edcc928297f8.0.${randomString}`,
    });
  }),

  http.get(`${BASE_URL}/getPoc`, async () => {
    await delay();

    return HttpResponse.json(poc);
  }),

  http.get(`${BASE_URL}/list`, async () => {
    await delay();

    return HttpResponse.json(list);
  }),
];
