import { certificates } from './certificates';
import { settings } from './settings';
import { user } from './user';

export default {
  namespaced: true,
  modules: {
    certificates,
    user,
    settings,
  },
};
