import * as actions from './actions';
import mutations from './mutations';

const state = {
  readyToCollect: false,
  isAuthenticating: false,
  loginState: 'idle', // idle, auth, scan, sign, fetching, error
  collectState: '',
  qrData: '',
  user: null,
  organizationName: '',
  isSessionWarningVisible: false,
};

// eslint-disable-next-line import/prefer-default-export
export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
};
