import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

const state = {
  isLoggedIn: false,
  user: {},
  authInfo: null,
  isAuthenticating: false,
  token: '',
};

export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
