export const authenticate = {
  orderRef: 'bedea56d-7b46-47b1-890b-f787c650bc93',
  autoStartToken: 'a4904c4c-3bb4-4e3f-8ac3-0e950e529e5f',
  qrStartToken: '67df3917-fa0d-44e5-b327-edcc928297f8',
  qrStartSecret: 'qrss',
};

export const poc = {
  id: 0,
  personNumber: '121212-1212',
  personNumberType: 'SOSE',
  name: 'Test Testsson',
  email: 'test@example.com',
  address: {
    id: 0,
    department: 'Department',
    streetName: 'Examplestreet',
    postCode: '121212',
    townName: 'Exampletown',
    country: 'Examplia',
    addressLine: '42 st',
    pointOfContact: 'Test Testsson',
    addressId: 0,
  },
  mobilePhone: '070707070707',
  creationDate: '2024-03-08T12:45:54.274Z',
  lastUpdated: '2024-03-08T12:45:54.274Z',
  swishService: {
    id: 0,
    bic: 'SWEDSE',
    swishNumber: '1234567890',
    organizationNumber: '1212121212',
    enrolmentId: '0000004JFG2SJRKTGD4BHP3CZ5',
    status: 'ENROLLED',
    creationDate: '2024-03-08T12:45:54.274Z',
    lastUpdated: '2024-03-08T12:45:54.274Z',
    pointOfContacts: ['Test Testsson'],
    certificates: [
      {
        id: 0,
        ahTrustOrderNumber: '123',
        ahTrustUser: 'TrustUser',
        certSlNo: '12341234',
        beginTime: '2024-03-08T12:45:54.274Z',
        endTime: '2024-03-08T12:45:54.274Z',
        issuerBic: 'SWEDSE',
        issuerName: 'Swedbank',
        subject: 'Cert subject',
        certType: 'Cert type',
        certEncoded: '0000004JFG1Q9T7Q2N82B0MYG7',
        status: 'VALID',
        requestedBy: 'Test Testsson',
        creationDate: '2024-03-08T12:45:54.274Z',
        swishService: 'C2B',
        certId: 0,
      },
    ],
    serviceId: 0,
  },
  pocId: 0,
};
