import moment from 'moment';

require('moment/locale/en-gb');
require('moment/locale/sv');

export const setLocale = ({ commit }, locale) => {
  commit('setLocale', { locale });
  moment.locale(locale);

  // Set language tag
  const html = document.documentElement;
  html.setAttribute('lang', locale);
};

export const init = ({ commit }, locale) => {
  moment.locale(locale);
  commit('init', { locale });

  // Set language tag
  const html = document.documentElement;
  html.setAttribute('lang', locale);
};
