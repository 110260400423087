import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '@/firebase';

const BASE_URL = process.env.VUE_APP_OPERATING_API_URL;

const auth = getAuth(firebaseApp);
const getToken = async () => auth.currentUser.getIdToken();

const fetch = async ({ uri, method, data = null }) => {
  const token = await getToken();

  return axios({
    method,
    url: `${BASE_URL}${uri}`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept-Language': '*',
    },
    data,
  })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const operatingInfoApi = {
  me: async () => fetch({ uri: '/me/', method: 'GET' }),

  list: async () => fetch({ uri: '/issues/', method: 'GET' }),

  history: async (batchKey) => {
    if (batchKey) {
      return fetch({ uri: `/issues/history?batchKey=${batchKey}`, method: 'GET' });
    }

    return fetch({ uri: '/issues/history', method: 'GET' });
  },

  show: async (id) => fetch({ uri: `/issues/${id}`, method: 'GET' }),

  create: async (issue) => fetch({ uri: '/issues/', method: 'POST', data: issue }),

  update: async (issue) => fetch({ uri: `/issues/${issue.id}`, method: 'PUT', data: issue }),

  delete: async (id) => fetch({ uri: `/issues/${id}`, method: 'DELETE' }),

  resolve: async (id) => fetch({ uri: `/issues/${id}/resolve`, method: 'PUT' }),
};

export default operatingInfoApi;
