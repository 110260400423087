import QRCode from 'qrcode';

const isValidField = (number, length) => {
  const regex = /[0-9]|\./;
  return number.length === length && regex.test(number);
};

const removeNonDigits = (value) => value.replace(/\D/g, '');

const formatPersonalNumber = (input) => {
  const personalNumber = removeNonDigits(input);

  if (personalNumber.length === 10) {
    const currentYear = new Date().getFullYear();
    const firstDigitsInput = personalNumber.substring(0, 2);
    const firstDigitsCurrentYear = currentYear.toString().substring(0, 2);
    const lastDigitsCurrentYear = currentYear.toString().substring(2, 4);

    return parseInt(firstDigitsInput, 10) + 18 > parseInt(lastDigitsCurrentYear, 10)
      ? parseInt(firstDigitsCurrentYear, 10) - 1 + personalNumber
      : firstDigitsCurrentYear + personalNumber;
  }

  return personalNumber;
};

const formatFields = ({ orgNrInput, ssnInput, swishNrInput }) => {
  const orgNr = removeNonDigits(orgNrInput);
  const swishNr = removeNonDigits(swishNrInput);
  let ssn;

  if (ssnInput) {
    ssn = formatPersonalNumber(ssnInput);
  }

  return { orgNr, swishNr, ssn };
};

const validateFields = ({ method, foreignOrgNr, data }) => {
  const validationErrors = {};

  const { orgNr, ssn, swishNr } = data;

  validationErrors.orgNrError =
    !foreignOrgNr && !(isValidField(orgNr, 12) || isValidField(orgNr, 10));

  validationErrors.swishNrError = !isValidField(swishNr, 10);

  if (method === 'mobileBankId') {
    validationErrors.ssnError = !isValidField(ssn, 12);
  }

  return validationErrors;
};

const generateQRCode = (qrData) =>
  QRCode.toDataURL(qrData, { errorCorrectionLevel: 'H', width: 400, margin: 3 });

export {
  isValidField,
  removeNonDigits,
  formatPersonalNumber,
  formatFields,
  validateFields,
  generateQRCode,
};
