const sortBy = (array, key) => [...array].sort((a, b) => b[key] - a[key]);

export const activeIssues = (state) =>
  sortBy(
    state.issues.filter((issue) => issue.resolved_at === null && issue.publish_at < Date.now()),
    'publish_at',
  );

export const upcomingIssues = (state) =>
  sortBy(
    state.issues.filter((issue) => issue.publish_at > Date.now()),
    'publish_at',
  );

export const historyIssues = (state) => state.history;

export const historyBatchKey = (state) => state.historyBatchKey;

export const isLoadingHistory = (state) => state.isLoadingHistory;

export const isLoading = (state) => state.activeFetches > 0;

export const getIssueById = (state) => (issueId) => {
  const result = state.issues.find((issue) => issue.id === issueId);
  if (result) return result;
  return state.history.find((issue) => issue.id === issueId);
};

export const banks = (state) => state.banks;

export const bankNames = (state) => state.banks.map((bank) => bank.fields.name);
