import moment from 'moment';

export const formatMoment = (value, format) => moment(value).format(format);

const isMaxDurationExceeded = (startTime, timeLimit) => {
  const now = new Date().getTime();
  const timeElapsed = now - startTime;

  return timeElapsed > timeLimit;
};

export const poll = ({
  interval,
  timeLimit,
  asyncFn,
  pollCallback,
  successCriteria,
  successCallback,
  timeoutCallback,
  errorCallback,
}) => {
  let timeoutID;
  let cancelled = false;

  async function call(startTime = new Date().getTime()) {
    if (isMaxDurationExceeded(startTime, timeLimit)) {
      timeoutCallback?.();
      return;
    }

    let res;
    try {
      res = await asyncFn();
    } catch (err) {
      if (!cancelled) {
        errorCallback(err);
      }

      return;
    }

    if (cancelled) {
      return;
    }

    if (res) {
      if (successCriteria(res)) {
        successCallback(res);
        return;
      }

      pollCallback(res);

      if (cancelled) {
        return;
      }
    }

    timeoutID = setTimeout(() => call(startTime), interval);
  }

  return {
    cancel: () => {
      clearTimeout(timeoutID);
      cancelled = true;
    },
    start: () => {
      call();
    },
  };
};
