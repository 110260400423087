import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

const state = {
  activeFetches: 0,
  issues: [],
  isLoadingHistory: false,
  history: [],
  historyBatchKey: null,
  modal: {
    isOpen: false,
    issue: {},
  },
  banks: [],
};

export const operatingInfo = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
