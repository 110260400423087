export const setSelectedPlatform = ({ commit }, payload) => {
  commit('setSelectedPlatform', payload);
  localStorage.setItem('selected_platform', payload);
};

export const initSelectedPlatform = ({ state, commit }) => {
  const storedPlatform = localStorage.getItem('selected_platform');
  const autoDetectedPlatform = state.platforms
    .find((platform) => navigator.platform.toLowerCase().indexOf(platform.navigatorValue) > -1);
  const autoDetectedPlatformValue = autoDetectedPlatform ? autoDetectedPlatform.value : null;
  const selectedPlatform = storedPlatform || autoDetectedPlatformValue || state.platforms[0].value;
  commit('setSelectedPlatform', selectedPlatform);
};
