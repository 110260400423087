import axios from 'axios';

const orgSearchApi = {
  search: async (orgNo) => {
    const request = await axios({
      method: 'GET',
      url: `http://sparky.getswish.pub.tds.tieto.com/swish-search/api/v1/search?entries=1&orgNo=${orgNo}`,
    });

    return request.data && request.data.results[0] ? request.data.results[0].organizationName : '';
  },
};

export default orgSearchApi;
