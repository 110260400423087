export default {
  common: {
    cancel: 'Avbryt',
    yes: 'Ja',
    no: 'Nej',
    by: 'Av',
    from: 'Från',
    to: 'Till',
    created: 'Skapad',
    updated: 'Uppdaterad',
    channel: 'Kanal',
    previous: 'Föregående',
    resolved: 'Avslutad',
    errors: {
      addissue: 'Ett problem uppstod, var god försök igen.',
    },
    placeholder: {
      date: 'Välj ett datum',
    },
  },

  filedrop: {
    header: 'DRA OCH SLÄPP',
    hint: 'Släpp din fil här eller',
    label: 'bläddra bland dina filer.',
    hover: 'Släpp för att ladda upp fil',
    button: 'Ta bort fil',
  },

  sidebar: {
    links: {
      operatingInfo: 'Driftinformation',
      newIssue: 'Ny driftstörning',
      certificates: 'Certifikat',
      newCertificate: 'Nytt certifikat',
    },
  },

  topbar: {
    dropdown: {
      profile: 'Mitt konto',
      logout: 'Logga ut',
    },
  },

  profile: {
    heading: 'Profil',
    accountDetails: 'Kontoinformation',
    settings: {
      heading: 'Inställningar',
      language: 'Språk',
    },
    labels: {
      name: 'Namn',
      email: 'E-mailadress',
      currentLanguage: 'Nuvarande språk',
    },
    buttons: {
      sendPasswordResetEmail: 'Återställ lösenord',
      sendPasswordResetEmailClicked: 'Återställningsmejl skickat',
    },
  },

  cookieBanner: {
    text: 'Vi använder cookies för att förbättra din användarupplevelse. Samtycker du till användandet av cookies?',
  },

  operatingInfo: {
    common: {
      appPrivate: 'App - Privat',
      appBusiness: 'App - Företag',
      apiExternal: 'API - Handlare',
      appAll: 'App - Alla',
      webExternal: 'Webb - Swish.nu',
      webBusiness: 'Webb - Företagswebben',
      webAll: 'Webb - Alla',
      all: 'Alla',
      newDisturbance: 'Ny driftstörning',
      publish: 'Publicera',
      published: 'Publicerad',
      publishChanges: 'Publicera ändringar',
      toBePublishedIn: 'Publiceras i',
      toBePublished: 'Publiceras',
      publishedIn: 'Publicerad i',
      publishIn: 'Publicera i',
      disturbance: 'Driftstörning',
      DISTURBANCE: 'Driftstörning',
      downtime: 'Driftstopp',
      DOWNTIME: 'Driftstopp',
      planned: 'Planerad',
      active: 'Pågående',
      disturbanceType: 'Störningstyp',
      message: 'Meddelande',
      title: 'Rubrik',
      edit: 'Ändra',
      preview: 'Granska',
    },

    validation: {
      noChannelSelected: 'Du måste välja åtminstone en kanal',
      noEmptyFields: 'Fyll i fält',
    },

    login: {
      heading: 'Admin Portal',
      labels: {
        email: 'Email',
        password: 'Lösenord',
      },
      placeholders: {
        email: 'Fyll i email',
        password: 'Skriv lösenord',
      },
      buttons: {
        submit: 'Logga in',
        lostPassword: 'Har du glömt ditt lösenord?',
        back: 'Tillbaka',
        sendPasswordResetEmail: 'Skicka återställningsmejl',
        sendPasswordResetEmailClicked: 'Återställningsmejl skickat',
      },
      firebaseErrors: {
        'auth/invalid-email': 'E-postadressen är felaktigt formatterad.',
        'auth/wrong-password': 'Lösenordet är ogiltigt.',
        'auth/user-not-found':
          'Användaren kunde inte hittas. Kontrollera att rätt e-postadress är ifylld.',
      },
    },

    create: {
      heading: 'Ny driftstörning',
      issueType: 'Typ av störning',
      issueProvider: 'Var är störningen?',
      issueBank: 'Vilken bank?',
      issueService: 'Påverkade tjänster',
      status: 'Status',
      channels: 'Skicka meddelande via',
      swish: 'Swish',
      unknown: 'Okänd',
      bank: 'Bank',
      supplier: 'Leverantör',
      bankId: 'BankID',
      bankgirot: 'Bankgirot',
      riksbanken: 'Riksbanken',
      merchants: 'Handel',
      app: 'App',
      private: 'Privat',
      companies: 'Företag',
      all: 'Alla',
      active: 'Pågående',
      planned: 'Planerat underhåll',
      dialogConfirm: 'Bekräfta publicering',
      placeholderBank: 'Välj bank',
      externalWarning:
        'Observera valet API - handlare kommer att meddela dem om att Swish inte kan användas som ett betalningsalternativ',

      dialog: {
        leaveTitle: 'Varning',
        leaveMessage:
          'Dina ändringar har inte blivit publicerade, är du säker på att du vill lämna vyn?',
        templateChangeHeader: 'Varning',
        templateChangeMessage:
          'Du har osparade förändringar som kommer att försvinna om du byter mall',
        templateChangeConfirm: 'Ja, byt mall',
      },

      buttons: {
        leave: 'Lämna',
        cancel: 'Avbryt',
      },
    },

    list: {
      heading: 'Driftinformation',
      ongoing: 'Publicerade',
      upcoming: 'Kommande',
      resolved: 'Avslutade',
      noOngoing: 'Inga pågående störningar',
      noPlanned: 'Inga kommande störningar',
      noResolved: 'Inga avslutade störningar',

      buttons: {
        newIssue: 'Ny driftstörning',
      },
    },

    issueModal: {
      common: {
        copy: 'kopiera',
        delete: 'ta bort',
        resolve: 'avsluta',
        update: 'uppdatera',
        ctaResolve: 'Vill du avsluta störningen?',
        ctaResolveDescription:
          'Meddelandet kommer att tas bort från app/webbsida. Om störningen är publicerad på webbsidan kommer den visas som “Avslutad” bland “Senaste händelser”.',
        ctaCopy: 'Vill du kopiera störningen?',
        ctaCopyDescription:
          'Du kommer att påbörja skapandet av en ny driftstörning med samma innehåll som denna',
        ctaDelete: 'Vill du ta bort störningen?',
        ctaDeleteDescription: 'Meddelandet kommer att tas bort från app/webbsida.',
        ctaUpdate: 'Vill du uppdatera störningen?',
        ctaUpdateDescription: 'Meddelandet kommer att uppdateras med dina ändringar',
      },
      buttons: {
        resolveIssue: 'Avsluta Störning',
        copy: 'Kopiera',
        delete: 'Ta bort',
      },
    },

    feedback: {
      title: 'Användarfeedback',
      description: 'Den här sidan visar den feedback som samlats in på developer.swish.nu.',
      page: 'Sida',
      comments: 'Kommentarer',
      votes: 'Röster',
      total: 'Betyg',
      upvotes: 'Nöjd',
      downvotes: 'Missnöjd',
      type: 'Typ',
      date: 'Datum',
      category: 'Kategori',
      message: 'Meddelande',
    },
  },

  company: {
    policy: 'Integritetspolicy',

    errors: {
      validationError: 'Numret som angetts är felaktigt, vänligen kontrollera och försök igen.',
      userCancel: 'Inloggningen avbröts.',
      noClient: 'Ingen BankIDklient hittades.',
      timeout: 'Signeringen tog för lång tid och avbröts, vänligen försök igen.',
      personNumber: 'Personnumret är inte kopplat till Swish. Vänligen kontakta din bank.',
      swishNumber: 'Swishnumret är inte giltigt, vänligen kontakta din bank.',
      notAuthorized: 'Du har inte rätt behörighet, vänligen kontakta din bank.',
      sign: 'En signering är redan igång.',
      swishService: 'Inget Swish-avtal kunde hittas, vänligen kontakta din bank.',
      common:
        "Något gick fel, vänligen försök igen. Om problem kvarstår, kontakta kundservice på support{'@'}getswish.se",
    },

    login: {
      safariWarning:
        'Standardinställningarna för cookies i Safari förhindrar oss från att logga in dig. Vi rekommenderar att du använder en alternativ webbläsare eller ändrar dina sekretessinställningar för att tillåta cookies från tredje part.',
      heading: 'Företagsportal',
      personalNumber: 'Personnummer',
      personalNumberPlaceholder: 'ååååmmdd-xxxx',
      organizationNumber: 'Organisationsnummer',
      organizationNumberPlaceholder: '10 eller 12 siffror',
      nonSwedishCheckbox: 'Utländskt organisationsnummer',
      swishNumber: 'Swish-nummer',
      submit: 'Logga in med BankID',
      collect: 'Öppna BankID på din enhet och skanna QR-koden',
      bankIdOnCard: 'BankID på kort/fil',
      mobileBankId: 'Mobilt bankID',
      qrAlt: 'QR-kod för inloggning med Mobilt BankID',
    },

    profile: {
      heading: 'Certifikathantering',
      subheading: 'Kontakta din bank för att ändra kontoinformationen.',
      update: 'Uppdatera',
    },

    error: {
      heading: 'Någonting blev fel',
    },

    sessionExpired: {
      heading: 'Du har loggats ut',
      text: 'Din session gick ut och du har loggats ut. Logga in igen för att fortsätta.',
    },

    certificates: {
      certificates: 'Certifikat',
      new: 'Nytt certifikat',
      choosePlatform: 'Välj plattform',
      currentStep: 'Steg {current} av {total}',

      issued: 'Skapat',
      validThrough: 'Giltigt t.o.m.',
      issuer: 'Utgivare',
      requestedBy: 'Skapat av',
      status: 'Status',
      download: 'Ladda ner',
      revoke: 'Återkalla',
      view: 'Visa',
      revoked: 'Återkallat',
      valid: 'Giltigt',

      revokeModal: {
        heading: 'Vill du återkalla certifikatet?',
        text: 'Att återkalla ett certifikat leder till avbrott i den kopplade Swish-lösningen och går inte att ångra.',
        confirmButton: 'Ja, återkalla',
        cancelButton: 'Stäng',
        confirmHeading: 'Certifikatet har nu återkallats',
        confirmText: 'Du hittar återkallade certifikat längst ner i listan.',
      },

      viewModal: {
        heading: 'Ditt certifikat',
        text: 'Ladda ner ditt certifikat som en fil eller kopiera texten direkt från textrutan.',
        cancelButton: 'Stäng',
      },

      supportEmail: {
        subject: 'Swish tekniksupport - certifikat',
        body: 'Beskriv ditt problem så hjälper vi dig.',
      },

      exportGuide: {
        done: 'Klar',
        tabs: {
          create: 'Nytt certifikat',
          heading: 'Exportera certifikat',
          import: 'Importera',
          export: 'Exportera',
          validate: 'Validera',
          distribute: 'Distribuera',
        },
        import: {
          button: 'Jag har importerat certifikatet',
        },
        export: {
          heading: 'Exportera certifikat',
          button: 'Jag har exporterat certifikatet',
        },
        validate: {
          heading: 'Validera certifikat',
        },
      },

      createGuide: {
        tabs: {
          heading: 'Skapa ett nytt certifikat',
          request: 'Förfrågan',
          generate: 'Generera',
          download: 'Ladda ner',
          export: 'Exportera',
        },
        request: {
          button: 'Jag har skapat en CSR-fil',
          skip: 'Hoppa över',
        },
        generate: {
          heading: 'Ladda upp CSR-fil',
          text: 'Hitta din sparade CSR-fil på datorn och ladda upp den här.',
          button: 'Generera certifikat',
          textUpload: 'Hitta din sparade CSR-fil på datorn och ladda upp den här.',
          textPaste: 'Eller klistra in från din CSR-fil',
          placeholder: 'Börjar oftast med "-----BEGIN CERTIFICATE REQUEST-----"',
          validationError:
            'CSR-filen hittades inte. Försök att ladda upp filen igen eller klistra in texten direkt i textrutan.',
        },
        download: {
          heading: 'Ditt certifikat är redo',
          buttonDownload: 'Ladda ner certifikat',
          done: 'Klar',
          buttonSkipGuide: 'Hoppa över exportguiden',
          empty:
            'Något har gått fel, det verkar inte finnas något certifikat. Gå tillbaka till föregående steg eller början av guiden och försök igen.',
        },
      },
    },
  },

  language: {
    toggle: 'Byt språk till {language}',
    languages: {
      en: 'engelska',
      sv: 'svenska',
    },
  },
};
